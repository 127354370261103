import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import heroDesktop from '../../common/hero/desktop.mp4';
import heroDesktopPoster from '../../common/hero/desktop_poster.png';

import heroMobile from '../../common/hero/mobile.mp4';
import heroMobilePoster from '../../common/hero/mobile_poster.png';

import { REGISTER_URL } from '../../common/constants';

import './theme.scss';

class Hero extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oldX: 0,
      oldY: 0
    };
  }

  componentDidMount() {
    const { onLoad } = this.props;
    if (this.video) {
      this.video.controls = false;
      this.video.width = '100vw';
      this.video.play();
    }

    if (onLoad) onLoad(this.video);
  }

  onOverlayClick = () => {
    if (this.video) {
      this.video.play();
    }
  };

  render() {
    const { isMobile, t } = this.props;
    const videoSrc = isMobile ? heroMobile : heroDesktop;
    const videoPosterSrc = isMobile ? heroMobilePoster : heroDesktopPoster;

    return (
      <div className="container black">
        <video
          poster={videoPosterSrc}
          ref={ref => {
            this.video = ref;
          }}
          className={'hero'}
          muted
          preload={'auto'}
          playsInline
          autoPlay
          loop
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
        <div className="overlay" onClick={this.onOverlayClick}>
          <div className="hero-message">
            <div className="big-hero-message">{t('dentist.hero.v2.title')}</div>
            <a href={REGISTER_URL} className="as-link dark hero">
              <div
                style={{ fontWeight: 'bold', maxWidth: '17rem', width: '100%' }}
                className="button primary main hero"
              >
                {t('common.startTrial')}
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Hero);
