import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import videoSrc from '../../common/illustrations/Review_CBCT_tab.mp4';
import videoPosterSrc from '../../common/illustrations/Review_CBCT_tab.jpg';
import reviewIcon from '../../common/illustrations/review.svg';

import './theme.scss';

const ReviewMobile = props => {
  const { onLoad } = props;
  const { t } = useTranslation();
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      onLoad && onLoad(videoRef.current);
    }
  }, [onLoad]);

  return (
    <section className="ea-full-width">
      <div className="easy-access ea-content">
        <div className="ea-row">
          <div className="ea-header">
            <img
              src={reviewIcon}
              className="ea-title-icon"
              alt={t('easyAccess.main.menu.review')}
            />
            <span className="ea-title">{t('easyAccess.main.menu.review')}</span>
          </div>
          <video
            poster={videoPosterSrc}
            ref={videoRef}
            className={'main-video'}
            muted
            preload={'auto'}
            playsInline
            autoPlay
            loop
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
          <div className="ea-message-container">
            <div className="ea-message-title">
              {t('easyAccess.review.v2.title')}
            </div>

            <div className="ea-message-body">
              <p>{t('easyAccess.review.v2.description.line1')}</p>

              <p>{t('easyAccess.review.v2.description.line2')}</p>

              <p>{t('easyAccess.review.v2.description.line3')}</p>

              <p>{t('easyAccess.review.v2.description.line4')}</p>

              <p>{t('easyAccess.review.v2.description.line5')}</p>

              <p>{t('easyAccess.review.v2.description.line6')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReviewMobile;
