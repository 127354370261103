import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FontIcon from '../../vendor/font_icon';

import motivational from '../../common/metalab/motivational@2x.png';
import motivational1X from '../../common/metalab/motivational.png';

import metalab from '../../common/metalab/metalab@2x.png';
import metalab1X from '../../common/metalab/metalab.png';

import mockup from '../../common/metalab/mockup.mp4';
import mockupPoster from '../../common/metalab/mockup@2x.png';

import './Experience.scss';

const Experience = () => {
  const { t } = useTranslation();
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoRef = useRef(null);

  const videoSrc = mockup;
  const videoPosterSrc = mockupPoster;

  const onEnded = () => {
    setVideoPlaying(false);
  };

  const onClick = useCallback(() => {
    if (videoRef.current) {
      if (videoPlaying) {
        setVideoPlaying(false);
        videoRef.current.pause();
      } else {
        setVideoPlaying(true);
        videoRef.current.play();
      }
    }
  }, [videoPlaying]);

  return (
    <section className="de-full-width">
      <div className="design-experience de-content">
        <div className="de-row">
          <div className="de-message-container">
            <div className="de-message-title">
              {t('metalab.experience.title')}
            </div>

            <div className="de-message-body">
              <p>{t('metalab.experience.description.line1')}</p>

              <p>{t('metalab.experience.description.line2')}</p>

              <p>{t('metalab.experience.description.line3')}</p>
            </div>
          </div>
          <div className="de-data-container-wrapper">
            <div className="de-data-container">
              <div className="de-video-container">
                <video
                  ref={videoRef}
                  poster={videoPosterSrc}
                  className={'metalabVideo'}
                  muted
                  preload={'auto'}
                  playsInline
                  onClick={onClick}
                  onEnded={onEnded}
                >
                  <source src={videoSrc} type="video/mp4" />
                </video>
                <div className="de-video-control" onClick={onClick}>
                  <FontIcon
                    value={videoPlaying ? 'pause' : 'play_circle_outline'}
                    className={`de-video-control-icon ${videoPlaying &&
                      'playing'}`}
                  />
                </div>
              </div>
              <img
                srcSet={`${motivational1X} 648w, ${motivational} 1296w`}
                src={motivational1X}
                className="de-main-image"
                alt={t('metalab.experience.title')}
              />
              <div className="de-ready-print-container">
                <span className="de-ready-print-title">
                  {t('metalab.ready.print.title')}
                </span>
                <img
                  srcSet={`${metalab1X} 648w, ${metalab} 1296w`}
                  src={metalab}
                  className="de-ready-print-image"
                  alt={t('easyAccess.main.menu.connect')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
