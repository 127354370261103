import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import videoSrc from '../../common/illustrations/Review_CBCT_tab.mp4';
import videoPosterSrc from '../../common/illustrations/Review_CBCT_tab.jpg';

import './theme.scss';

class Review extends Component {
  componentDidMount() {
    if (this.video) {
      const { onLoad } = this.props;
      if (onLoad) onLoad(this.video);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <section className="ea-full-width">
        <div className="easy-access ea-content">
          <div className="ea-row">
            <video
              poster={videoPosterSrc}
              ref={ref => {
                this.video = ref;
              }}
              className={'main-video'}
              muted
              preload={'auto'}
              playsInline
              autoPlay
              loop
            >
              <source src={videoSrc} type="video/mp4" />
            </video>
            <div className="ea-message-container">
              <div className="ea-message-title">
                {t('easyAccess.review.v2.title')}
              </div>

              <div className="ea-message-body">
                <p>{t('easyAccess.review.v2.description.line1')}</p>

                <p>{t('easyAccess.review.v2.description.line2')}</p>

                <p>{t('easyAccess.review.v2.description.line3')}</p>

                <p>{t('easyAccess.review.v2.description.line4')}</p>

                <p>{t('easyAccess.review.v2.description.line5')}</p>

                <p>{t('easyAccess.review.v2.description.line6')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(Review);
